import React from 'react';
import { NavLink } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

const inactiveStyleHome = "inline-flex items-center py-3 px-3 my-4 rounded text-white hover:text-green-800 text-4xl cursive tracking-widest"


const activeStyle = "inline-flex items-center py-3 px-3 my-4 rounded text-red-200 hover:text-green-800 text-red-100 bg-red-700"
const inactiveStyle = "inline-flex items-center py-3 px-3 my-4 rounded text-red-200 hover:text-green-800"

export default function NavBar() {
    return (
       <header className='bg-red-600'>
            <div className='container mx-auto flex justify-between'>
                <nav className='flex'>
                    <NavLink 
                        to="/" 
                        end 
                        className={inactiveStyleHome}
                    >
                        Hello!
                    </NavLink>
                    <NavLink 
                        to="/post" 
                        className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
                    >
                        Blog posts
                    </NavLink>
                    <NavLink 
                        to="/project" 
                        className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
                    >
                        Projects
                    </NavLink>
                    <NavLink 
                        to="/about" 
                        className={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
                    >
                        About me
                    </NavLink>
                </nav>
                <div className='inline-flex py-3 px-3 my-6'>
                    <SocialIcon url='https://twitter.com/Jonnytow' className='mr-4' target='_blank' fgColor="#FFF" style={{height: 35, width: 35}}/>
                    <SocialIcon url='https://www.youtube.com/channel/UC5ZmSEnt4cSXzFVZ3Pc8y8w' className='mr-4' target='_blank' fgColor="#FFF" style={{ height: 35, width: 35 }} />
                    <SocialIcon url='https://www.linkedin.com/in/jonathan-townend-191099a0/' className='mr-4' target='_blank' fgColor="#FFF" style={{ height: 35, width: 35 }} />
                </div>
            </div>
       </header>
    )
}